@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');


.motivation-container {
    height: 40rem;
    width: 100vw;
    background-image: url('../../assets/MBg4.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: left top;
    background-size: cover;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    margin: 0;
}

.motivation-text {
    color: #ffffff;
    font-size: 5rem;
    font-weight: bold;
    text-transform: uppercase;

    display: flex;
    flex-direction: column;

    margin-left: 3rem;
}

.stroke-text1 {
    letter-spacing: 4px;
    font-family: 'Roboto', sans-serif;
    color: transparent;
    -webkit-text-stroke-width: 4px;
    -webkit-text-stroke-color: var(--orange);
}

/* .fill-text{
    color: var(--orange);
    -webkit-text-stroke-width: 0px;
} */

@media screen and (max-width: 666px) {
    .motivation-container {
        height: 22rem;
        justify-content: start;
    }

    .motivation-text {
        font-size: xx-large;
    }

    .stroke-text1 {
        -webkit-text-stroke-width: 1.4px;
    }
}

/* @media screen and (max-width: 666px) {
    .motivation-container {
        height: 22rem;
        justify-content: start;
    }

    .motivation-text {
        font-size: xx-large;
    }

    .stroke-text1 {
        -webkit-text-stroke-width: 1.4px;
    }
} */