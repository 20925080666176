.programs-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
}

.programs-heading {
    display: flex;
    gap: 5rem;
    align-items: center;
    justify-content: center;
}

.programs-heading>span {
    font-size: 3rem;
    font-weight: bold;
    font-style: italic;
    text-transform: uppercase;
}

.programs-heading>:nth-child(2) {
    color: white;
}

.program-cards {
    display: flex;
    gap: 1rem;
}

.card {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: white;
    background-color: var(--gray);
    padding: 2rem;
    justify-content: space-between;
}

.card>:nth-child(1) {
    width: 2rem;
    height: 2rem;
    fill: white;
}

.card-text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.card-text>:nth-child(1) {
    font-weight: 600;
    font-size: 1rem;
}

.card-text>:nth-child(2) {
    font-size: 0.9rem;
    line-height: 1.6rem;
}

.join-now-program>span {
    text-transform: capitalize;
}

.join-now-program {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.join-now-program>img {
    width: 1rem;
}

.card:hover {
    background-color: var(--orange);
}


/* adding responsiveness  */

@media screen and (max-width: 842px) {
    .programs-heading {
        gap: 2rem;
    }
}

@media screen and (max-width: 768px) {
    .programs-heading {
        flex-direction: column;
        gap: 1rem;
        font-size: x-large;
        align-items: center;
        justify-content: center;
    }

    .program-cards {
        flex-direction: column;
    }
}


@media screen and (max-width: 540px) {
    .programs-heading {
        font-size: medium;
    }
}