.header {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.logo {
    width: 10rem;
    height: 3rem;
}

.header-menu {
    display: flex;
    list-style-type: none;
    gap: 2rem;
    color: white;
}

.header-menu>li:hover {
    cursor: pointer;
    color: var(--orange);
}



@media screen and (max-width: 768px) {

    .header-menu {
        position: fixed;
        right: 2rem;
        top: 3rem;
        z-index: 99;
        flex-direction: column;
        padding: 2rem;
        background-color: var(--appColor);
    }

    .bars-icon {
        width: 1.5rem;
        height: 1.5rem;
        display: block;
        position: absolute;
        right: 2rem;
        top: 1rem;
    }

}