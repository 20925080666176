.testimonial-container {
    display: flex;
    padding: 0 2rem;
}

.testimonial-left {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.testimonial-right {
    flex: 1 1;
    position: relative;
    /* border: 2px solid red; */
    height: 25rem;
}

.testimonial-left>:nth-child(1) {
    text-transform: uppercase;
    color: var(--orange);
    font-weight: bold;
}

.testimonial-left>:nth-child(2) {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 3rem;
    font-style: italic;
}

.testimonial-left>:nth-child(3) {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 3rem;
    color: white;
}

.testimonial-left>:nth-child(4) {
    font-size: 1rem;
    color: white;
    line-height: 2.5rem;
    word-spacing: 5px;
    letter-spacing: 1px;
}

.testimonial-left>:nth-child(5)>:nth-child(1) {
    color: var(--orange);
}

.testimonial-left>:nth-child(5)>:nth-child(2) {
    color: white;
}

.testimonial-images {
    position: absolute;
    top: 1rem;
    right: 3rem;
    /* border: 3px solid blue; */
    width: 20rem;
    height: 25rem;
}

.testimonial-images>img {
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.testimonial-images>:nth-child(1) {
    width: 17rem;
    height: 20rem;
    position: absolute;
    border: 2px solid var(--orange);
    top: 0rem;
    right: 1.8rem;
    background-color: transparent;
}

.testimonial-images>:nth-child(2) {
    width: 17rem;
    height: 20rem;
    position: absolute;
    top: 1.8rem;
    right: 0;
    background-color: var(--orange);
}

.testimonial-right>:nth-child(2) {
    position: absolute;
    bottom: 1rem;
    left: 3rem;

    display: flex;
    gap: 1rem;

}

.testimonial-right>:nth-child(2)>img {
    width: 1.5rem;
    cursor: pointer;
}

@media screen and (max-width: 915px) {
    .testimonial-images>img {
        width: 14rem;
        height: 18rem;
    }

    .testimonial-images>:nth-child(1) {
        width: 14rem;
        height: 18rem;
    }

    .testimonial-images>:nth-child(2) {
        width: 14rem;
        height: 18rem;
    }

    .testimonial-images {
        margin-right: -2rem;
    }
}

@media screen and (max-width: 768px) {
    .testimonial-container {
        flex-direction: column;
        gap: 3rem;
        margin-top: -2rem;
    }

    .testimonial-left>:nth-child(2),
    .testimonial-left>:nth-child(2) {
        font-size: xx-large;
    }

    .testimonial-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        height: 25rem;
    }

    .testimonial-images {
        justify-content: center;
        position: absolute;
        align-self: center;
        /* border: 2px solid red; */
        margin-top: 1rem;
    }

    .testimonial-images>:nth-child(1) {
        align-self: center;
    }

    .testimonial-right>:nth-child(2) {
        position: absolute;
        bottom: 0rem;
        left: 2rem;

        display: flex;
        gap: 1rem;
    }
}

@media screen and (max-width: 395px) {
    .testimonial-images>img {
        width: 14rem;
        height: 17rem;
    }

    .testimonial-images>:nth-child(1) {
        width: 14rem;
        height: 17rem;
    }

    .testimonial-images>:nth-child(2) {
        width: 14rem;
        height: 17rem;
    }
}

@media screen and (max-width: 282px) {
    .testimonial-images>img {
        width: 10rem;
        height: 14rem;
    }

    .testimonial-images>:nth-child(1) {
        width: 10rem;
        height: 14rem;
    }

    .testimonial-images>:nth-child(2) {
        width: 10rem;
        height: 14rem;
    }

    /* .testimonial-images {
        margin-right: -2rem;
    } */
}

